<template>
  <tj-login
    v-model="formData"
    :options="formOptions"
    title="管理后台"
    full-title="管理后台"
    sub-title="Management System"
    @submit="onSubmit"
  ></tj-login>
</template>

<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import store from '@/store';
import { setToken } from '@/hooks/token';
import request from '@/hooks/request';

const router = useRouter();
const formData = reactive({
  username: 'admin',
  password: 'admin',
});
const formOptions = [{
  label: '账号',
  prop: 'username',
  rules: { required: true, message: '请输入账号' },
}, {
  label: '密码',
  type: 'password',
  prop: 'password',
  rules: { required: true, message: '请输入密码' },
}];
const onSubmit = () => {
  request({
    method: 'post',
    url: '/admin/login',
    data: formData,
  }).then(({ access_token: token }) => {
    store.commit('setToken', token);
    setToken(token);
    router.push({
      path: '/',
    });
  });
};
</script>
